export const getURLImage = (tipo, nivel) => {
  if (tipo !== "Via Liberada") {
    if (nivel === "Alagamento")
      return window.location.origin + "/img/alertas/acumulo_de_agua.jpeg";
    if (nivel === "Sinistro de Trânsito")
      return window.location.origin + "/img/alertas/sinistro_de_transito.jpeg";
    if (nivel === "Obras na via")
      return window.location.origin + "/img/alertas/obra_na_via.jpeg";
    if (nivel === "Evento")
      return window.location.origin + "/img/alertas/evento_cultural.jpg";
    if (nivel === "Manifestação")
      return window.location.origin + "/img/alertas/passeatas.jpeg";
    if (nivel === "Bloqueio Ciclovia")
      return window.location.origin + "/img/alertas/bloqueio_ciclovia.jpeg";
    if (nivel === "Queda de árvore")
      return window.location.origin + "/img/alertas/queda_de_arvore.jpeg";
    if (nivel === "Desvio do Transporte Público")
      return (
        window.location.origin + "/img/alertas/desvio_transporte_publico.jpeg"
      );
    if (nivel === "Desvio de Transito")
      return window.location.origin + "/img/alertas/desvio_transito.jpeg";
    if (nivel === "Lentidão na Via")
      return window.location.origin + "/img/alertas/lentidao_na_via.jpg";
    if (nivel === "Obstrução na via")
      return window.location.origin + "/img/alertas/obstrucao_na_via.jpeg";
  }
  return window.location.origin + "/img/alertas/via_liberada.jpeg";
};
