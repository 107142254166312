<template>
    <div class="container">
        <div class="row " style="max-width: 478px">
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Tipo</label><br>
                    <select class="form-select input-select" @input="CHANGE_SELECTED_TIPO">
                        <option v-for="(tipo, index) in getterTipo.options" :key="index" :value="tipo.option">
                            {{ tipo.option }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Previsão de liberação</label><br>
                    <input type="text" class="form-select input-text" placeholder="Digite previsão de liberação" @input="CHANGE_SELECTED_PREVISAO"
                        :disabled="disabled" maxlength="60">
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Nivel de Evento</label><br>
                    <select class="form-select input-select" @input="CHANGE_SELECTED_NIVEL" :disabled="disabled">
                        <option v-for="(nivel, index) in getterNivel.options" :key="index" :value="nivel.option">
                            {{ nivel.option }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Via</label><br>
                    <input type="text" class="input-text" placeholder="Digite a Via/Zona" @input="CHANGE_INPUT_VIA" maxlength="60">
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Sentido</label><br>
                    <input type="text" class="input-text" placeholder="Digite o Sentido da Via"
                        @input="CHANGE_INPUT_SENTIDO" maxlength="60">
                </div>
            </div>
            <div class="col">
                <div class="mt-4">
                    <label class="form-label label-input">Informar trecho: </label>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="trecho" id="sim"
                            @input="CHANGE_SELECTED_INFORMA_TRECHO(true)">
                        <label class="form-check-label" for="sim">
                            SIM
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="trecho" id="nao" checked
                            @input="CHANGE_SELECTED_INFORMA_TRECHO(false)">
                        <label class="form-check-label" for="nao">
                            NÃO
                        </label>
                    </div>
                </div>
            </div>
            <div v-if="getterInformaTrecho.selected" class="col">
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Trecho 1</label><br>
                    <input type="text" class="input-text" placeholder="" @input="CHANGE_SELECTED_INFORMA_TRECHO_ONE" maxlength="60">
                </div>
                <div class="mt-4">
                    <label for="evento" class="form-label label-input">Trecho 2</label><br>
                    <input type="text" class="input-text" placeholder="" @input="CHANGE_SELECTED_INFORMA_TRECHO_TWO" maxlength="60">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: 'CardOpcoesAlertas',
    computed: {
        ...mapGetters('alerta', [
            'getterTipo',
            'getterPrevisao',
            'getterNivel',
            'getterSentido',
            'getterVia',
            'getterInformaTrecho'
        ]),

        disabled() {
            return this.getterTipo.selected === 'Via Liberada' ? true : false
        }
    },
    methods: {
        ...mapMutations('alerta', [
            'CHANGE_SELECTED_TIPO',
            'CHANGE_SELECTED_PREVISAO',
            'CHANGE_SELECTED_NIVEL',
            'CHANGE_INPUT_VIA',
            'CHANGE_INPUT_SENTIDO',
            'CHANGE_SELECTED_INFORMA_TRECHO',
            'CHANGE_SELECTED_INFORMA_TRECHO_ONE',
            'CHANGE_SELECTED_INFORMA_TRECHO_TWO'
        ]),
    }
}
</script>