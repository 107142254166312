<template>
    <div class="container my-5 conteudo-card-mensagem">
        <div class="card-cabecalho mt-5">
            PREVIEW DA MENSAGEM POR WPP
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="sub-title-mensagem ">
                    <p class="text-capitalize">WhatsApp</p>
                    <p class="text-capitalize">Prefeitura de Porto Alegre – Trânsito.</p>
                </div>
                <div class="text-card-mensagem">
                    <PreviewMessage :tipo="getterTipo.selected" :previsao="getterPrevisao"
                        :nivel="getterNivel.selected" :via="getterVia" :sentido="getterSentido"
                        :trechoUm="getterInformaTrecho.trecho_one" :trechoDois="getterInformaTrecho.trecho_two" />
                </div>
            </div>
            <div class="mx-2 mt-0 py-0">
                <img :src="url" class="card-img-bottom img-card-wpp">
            </div>
        </div>
    </div>
</template>

<script>
import PreviewMessage from '@/components/message/PreviewMessage.vue'

import { mapGetters } from 'vuex';

export default {
    components: { PreviewMessage },
    name: 'CardPreviewWPP',
    computed: {
        ...mapGetters('alerta', [
            'getterTipo',
            'getterVia',
            'getterPrevisao',
            'getterNivel',
            'getterInformaTrecho',
            'getterSentido'
        ]),
        url() {
            if (this.getterTipo.selected !== 'Via Liberada') {
                if (this.getterNivel.selected === 'Alagamento') return window.location.origin + "/img/alertas/acumulo_de_agua.jpeg"
                if (this.getterNivel.selected === 'Sinistro de Trânsito') return window.location.origin + "/img/alertas/sinistro_de_transito.jpeg"
                if (this.getterNivel.selected === 'Obras na via') return window.location.origin + "/img/alertas/obra_na_via.jpeg"
                if (this.getterNivel.selected === 'Evento') return window.location.origin + "/img/alertas/evento_cultural.jpg"
                if (this.getterNivel.selected === 'Manifestação') return window.location.origin + "/img/alertas/passeatas.jpeg"
                if (this.getterNivel.selected === 'Bloqueio Ciclovia') return window.location.origin + "/img/alertas/bloqueio_ciclovia.jpeg"
                if (this.getterNivel.selected === 'Queda de árvore') return window.location.origin + "/img/alertas/queda_de_arvore.jpeg"
                if (this.getterNivel.selected === 'Desvio do Transporte Público') return window.location.origin + "/img/alertas/desvio_transporte_publico.jpeg"
                if (this.getterNivel.selected === 'Desvio de Transito') return window.location.origin + "/img/alertas/desvio_transito.jpeg"
                if (this.getterNivel.selected === 'Lentidão na Via') return window.location.origin + "/img/alertas/lentidao_na_via.jpg"
                if (this.getterNivel.selected === 'Obstrução na via') return window.location.origin + "/img/alertas/obstrucao_na_via.jpeg"
            }
            return window.location.origin + "/img/alertas/via_liberada.jpeg"
        }
    },
}
</script>