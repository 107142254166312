<template>
    <div class="container conteudo-card-mensagem">
        <div class="card-cabecalho">
            PREVIEW DA MENSAGEM POR SMS
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="text-card-mensagem">
                    <PreviewMessage :tipo="getterTipo.selected" :previsao="getterPrevisao"
                        :nivel="getterNivel.selected" :via="getterVia" :sentido="getterSentido"
                        :trechoUm="getterInformaTrecho.trecho_one" :trechoDois="getterInformaTrecho.trecho_two" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreviewMessage from '@/components/message/PreviewMessage.vue'

import { mapGetters } from 'vuex';

export default {
    components: { PreviewMessage },
    name: 'CardPreviewSMS',
    computed: {
        ...mapGetters('alerta', [
            'getterTipo',
            'getterVia',
            'getterPrevisao',
            'getterNivel',
            'getterInformaTrecho',
            'getterSentido'
        ]),
    },
}
</script>