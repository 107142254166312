<template>
  <sidenav class="side-nav" />
  <main class="conteudo-main">
    <navbar />
    <router-view />
  </main>
</template>
<script>
import Sidenav from "./components/Sidenav";
import Navbar from "./components/Navbars/Navbar.vue";

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
  },

  created() {
    console.log('Ambiente:', process.env.VUE_APP_AMBIENTE ?? window._env_.VUE_APP_AMBIENTE);
  }

};
</script>
