<template>
  <ul ref="side-itens" class="nav w-100 h-100">
    <div class="icon-sidebar-center">
      <li class="nav-item  active">
        <a class="navbar-brand" href="#">
          <img class="icon-sidebar" src="../../assets/img/icons/icon-dashboard.png" />
        </a>
      </li>
      <li class="nav-item">
        <a class="navbar-brand" href="#">
          <img class="icon-sidebar" src="../../assets/img/icons/icon-page.png" />
        </a>
      </li>
      <li class="nav-item">
        <a class="navbar-brand" href="#">
          <img class="icon-sidebar" src="../../assets/img/icons/icon-calendar.png" />
        </a>
      </li>
      <li class="nav-item">
        <a class="navbar-brand" href="#">
          <img class="icon-sidebar" src="../../assets/img/icons/icon-config.png" />
        </a>
      </li>
      <li class="nav-item">
        <a class="navbar-brand" href="#">
          <img class="icon-sidebar" src="../../assets/img/icons/icon-globo.png" />
        </a>
      </li>
      <div class="nav-item-footer">
        <li class="nav-item">
          <a class="" href="/disparaAlertas">
            <img class="icon-sidebar" src="../../assets/img/icons/icon-home.png" />
          </a>
        </li>
      </div>

    </div>


  </ul>
</template>
<script>
export default {
  name: "SidenavList",
};
</script>
