<template>
    <div>
        <main>
            <div v-if="this.tipo == 'Via Liberada'">
                <!-- via liberada sem trecho -->
                <div v-if="!this.$store.state.alerta.informa_trecho.selected">
                    <p>
                        <strong v-if="!this.via">Via não informado</strong>{{ via }}
                        sentido <strong v-if="!this.sentido">Sentido não Informado</strong><span v-if="this.sentido">({{
                            sentido }})</span>
                        foi liberada e segue com o trânsito normalizado.
                    </p>
                </div>
                <!-- via liberada com trecho -->
                <div v-else>
                    <p>
                        <strong v-if="!this.via">Via não informado</strong>{{ via }}
                        sentido <strong v-if="!this.sentido">Sentido não Informado</strong><span v-if="this.sentido">({{
                            sentido }})</span>
                        foi liberada do(a)
                        <strong v-if="!this.trechoUm">Trecho 1 não Informado</strong> {{ trechoUm }} ao(a)
                        <strong v-if="!this.trechoDois">Trecho 2 não Informado</strong>{{ trechoDois }} e segue com o
                        trânsito normalizado.
                    </p>
                </div>
            </div>

            <div v-else>
                <!-- alerta sem trecho -->
                <div v-if="!this.$store.state.alerta.informa_trecho.selected">
                    <p>
                        {{ tipo }} <span v-if="this.tipo != 'EPTC informa:'">na</span> <strong
                            v-if="!this.via">Via não informado</strong>{{ via }}
                        sentido <strong v-if="!this.sentido">Sentido não informado</strong><span v-if="this.sentido">({{
                            sentido }})</span>.
                        <strong v-if="!this.previsao">Previsão não informado</strong>{{ previsao }}. Evite a região.
                    </p>
                </div>
                <!-- alerta com trecho -->
                <div v-else>
                    <p>
                        {{ tipo }} na <strong
                            v-if="!this.via">Via não informado</strong>{{ via }}
                        sentido <strong v-if="!this.sentido">Sentido não informado</strong><span v-if="this.sentido">({{
                            sentido }})</span>
                        do(a) <strong v-if="!this.trechoUm">Trecho 1 não Informado</strong>{{ trechoUm }}
                        ao(a) <strong v-if="!this.trechoDois">Trecho 2 não Informado</strong>{{ trechoDois }}.
                        <strong v-if="!this.previsao">Previsão não informado</strong>{{ previsao }}.
                        Evite
                        a
                        região.
                    </p>
                </div>
            </div>
            <div>
                <p class="card-text ">💡 Você recebeu esta mensagem, pois tem este local ativo em seus
                    alertas de trânsito.</p>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: "PreviewMessage",
    props: {
        tipo: {
            type: String,
            required: false,
            default: ''
        },
        previsao: {
            type: String,
            required: false,
            default: ''
        },
        nivel: {
            type: String,
            required: false,
            default: ''
        },
        via: {
            type: String,
            required: false,
            default: ''
        },
        sentido: {
            type: String,
            required: false,
            default: ''
        },
        trechoUm: {
            type: String,
            required: false,
            default: ''
        },
        trechoDois: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>